import compose from 'lodash/flowRight'
import * as ReactRedux from 'react-redux'
import {bindActionCreators} from 'redux'

import {AppDispatch} from '../../actions/types'
import withEffect from '../../hocs/withEffect'
import type {State} from '../../reducers/types'
import {showDsl, hideDsl, initDslOptions} from '../Dsl/Dsl.actions'
import {getDslOptions} from '../Dsl/Dsl.selectors'

import ShowDsl from './ShowDsl'

type Props = {
  controlId: string
  helpUrl: string
  onShowDsl: () => unknown
  onHideDsl: () => unknown
}
type StateProps = {
  dslMode: boolean | null | undefined
}

const mapStateToProps = (state: State, props: Props): StateProps => ({
  dslMode: getDslOptions(state, props.controlId)?.show,
})

const mapDispatchToProps = (dispatch: AppDispatch, {controlId, onShowDsl, onHideDsl}: Props) => ({
  onShowDslClick: () => {
    dispatch(showDsl(controlId))

    if (onShowDsl) {
      onShowDsl()
    }
  },
  onShowUIClick: () => {
    dispatch(hideDsl(controlId))

    if (onHideDsl) {
      onHideDsl()
    }
  },
  ...bindActionCreators(
    {
      initDslOptionsFromDefault: initDslOptions,
    },
    dispatch,
  ),
})

const connector = ReactRedux.connect(mapStateToProps, mapDispatchToProps)
export default compose(
  connector,
  withEffect(
    (props: Props & ReactRedux.ConnectedProps<typeof connector>) => props.controlId,
    props => props.initDslOptionsFromDefault,
    (id, init) => {
      init(id)
    },
  ),
)(ShowDsl)
