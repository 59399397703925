import type {State} from '../../../../../../reducers/types'
import {getEndpointForBranches} from '../../../../../../rest/branches'
import {getBranches, getBuildType, getProjectOverviewLocator} from '../../../../../../selectors'
import {getProjectBuildTypeFilter} from '../../../../../../types'

import type {FetcherOwnProps} from './BuildTypeLine.types'

const MIN_AMOUNT_OF_FINISHED_BUILDS = 50
const MULTIPLE_FACTOR_FOR_QUEUED_COUNT = 2
export const getBuildTypeLineLocator: (arg0: State, arg1: FetcherOwnProps) => string = (
  state,
  {buildTypeId, branch},
) => {
  const buildType = getBuildType(state, buildTypeId)
  const projectOrBuildTypeNode = getProjectBuildTypeFilter({
    projectId: buildType?.projectId ?? null,
  })
  const endpoint = getEndpointForBranches({
    node: projectOrBuildTypeNode,
    policy: 'ACTIVE_HISTORY_AND_ACTIVE_VCS_BRANCHES',
    includeSubprojects: true,
  }).data
  const branches = getBranches(state, endpoint)
  const branchesCount = branches?.length ?? 1
  const finishedCount =
    branchesCount < MIN_AMOUNT_OF_FINISHED_BUILDS ? MIN_AMOUNT_OF_FINISHED_BUILDS : branchesCount
  const runningCount = finishedCount * MULTIPLE_FACTOR_FOR_QUEUED_COUNT
  const queuedCount = finishedCount * MULTIPLE_FACTOR_FOR_QUEUED_COUNT
  return getProjectOverviewLocator(state, {
    runningCount,
    queuedCount,
    finishedCount,
    branch,
    projectBuildtype: getProjectBuildTypeFilter({
      buildTypeId,
    }),
  })
}
