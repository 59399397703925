import getUID from '@jetbrains/ring-ui/components/global/get-uid'
import LoaderInline from '@jetbrains/ring-ui/components/loader-inline/loader-inline'
import classNames from 'classnames'
import {useEffect, useCallback, useMemo} from 'react'
import type {ReactElement, ReactHTML} from 'react'

import {useDispatch, useSelector} from 'react-redux'

import {fetchHtml} from '../../../actions'
import {useLazyLoading} from '../../../hocs/withLazyLoading'
import useMyId from '../../../hooks/useMyId'
import {getHtml} from '../../../selectors'
import {Namespaces} from '../../../utils/namespaces'
import {subscribeOnUserEvents} from '../../../utils/subscriber'
import {USER_PERMISSIONS_CHANGED} from '../../../utils/subscriptionEvents'
import {useSequenceLoaderReadyHandler} from '../SequenceLoader/SequenceLoader.hooks'
import TruncateHtml from '../TruncateHtml/TruncateHtml'

import type {OwnProps, Props} from './InjectHtml.types'

import {getScripts, withoutScripts} from './InjectHtml.utils'

import styles from './InjectHtml.css'

function InjectHtml({
  className,
  loaderClassName,
  content,
  placeholder,
  lines,
  lineHeight,
  TagName = 'div',
  withToggleButton,
  contentRef,
  trimContent,
  showLoader,
  showPlaceholder = true,
  wrapperProps,
  onLoad,
}: Props) {
  const id = useMemo(() => getUID('html-'), [])

  const insertAndRunScripts = useCallback(() => {
    const scriptsContent = getScripts(content)
    const container = document.querySelector(`#${id}`)

    if (!container || scriptsContent == null) {
      return
    }

    const range = document.createRange()
    range.selectNodeContents(container)
    range.deleteContents()

    range.setStart(container, 0)
    container.appendChild(range.createContextualFragment(scriptsContent))
  }, [content, id])

  const loadHandler = useCallback(() => {
    insertAndRunScripts()
    onLoad?.()
  }, [insertAndRunScripts, onLoad])

  useEffect(() => {
    if (content != null) {
      loadHandler()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadHandler()
  }, [content, loadHandler])

  const renderContent = useCallback(
    (localContent: string | null | undefined = content, isPlaceholder: boolean = false) => {
      if (localContent == null) {
        return null
      }

      const resultElementProps = {
        ref: contentRef,
        className: classNames(className, {
          [styles.placeholder]: isPlaceholder,
          [styles.inline]: lines === 1,
        }),
      }
      const resultElement = isPlaceholder ? (
        <TagName {...resultElementProps}>{localContent}</TagName>
      ) : (
        <TagName
          {...resultElementProps}
          dangerouslySetInnerHTML={{
            __html: withoutScripts(trimContent === true ? localContent.trim() : localContent),
          }}
        />
      )

      if (typeof lines === 'number' && lines > 0) {
        return (
          <TruncateHtml
            containerRef={contentRef}
            className={classNames(className, {
              [styles.placeholder]: isPlaceholder,
              [styles.inline]: lines === 1,
            })}
            clamp={lines}
            lineHeight={lineHeight}
            withToggleButton={withToggleButton}
          >
            {resultElement}
          </TruncateHtml>
        )
      }

      return resultElement
    },
    [TagName, className, content, contentRef, lineHeight, lines, trimContent, withToggleButton],
  )

  const renderPlaceholder = useCallback(
    () =>
      content == null && showPlaceholder && placeholder != null
        ? renderContent(placeholder, true)
        : null,
    [content, placeholder, renderContent, showPlaceholder],
  )

  const renderLoader = useCallback(
    () =>
      showLoader !== false && content == null && (!showPlaceholder || placeholder == null) ? (
        <div className={loaderClassName}>
          <LoaderInline />
        </div>
      ) : null,
    [content, loaderClassName, placeholder, showLoader, showPlaceholder],
  )

  return (
    <>
      <span {...wrapperProps} id={id} />
      {renderContent()}
      {renderPlaceholder()}
      {renderLoader()}
    </>
  )
}

function InjectHtmlWrapper(props: OwnProps) {
  const {absolute, path, reloadOnPermissionsChange, withLazyLoading} = props
  const myId = useMyId()
  const dispatch = useDispatch()

  const content = useSelector(state => getHtml(state, path))
  const isReady = content != null

  const load = useCallback(() => {
    if (!isReady && path != null) {
      dispatch(fetchHtml(path, null, absolute))
    }
  }, [isReady, path, dispatch, absolute])

  const lazyLoadingProps = useLazyLoading<string, HTMLSpanElement>({
    namespace: Namespaces.HTML,
    childUsesVisibility: false,
    ignoreFetcher: true,
    id: path ?? '',
    needsDetails: !isReady && !reloadOnPermissionsChange,
    fetchDetails: load,
    defaultVisible: !withLazyLoading,
  })

  useEffect(
    () =>
      myId != null && reloadOnPermissionsChange === true && path != null
        ? subscribeOnUserEvents(`html:${path}`, myId, [USER_PERMISSIONS_CHANGED], load)
        : undefined,
    [content, load, myId, path, reloadOnPermissionsChange],
  )
  useSequenceLoaderReadyHandler(isReady)

  return <InjectHtml {...props} {...lazyLoadingProps} content={content} />
}

export default InjectHtmlWrapper as <T extends keyof ReactHTML = 'div'>(
  props: Props<T>,
) => ReactElement | null
