import Button from '@jetbrains/ring-ui/components/button/button'
import * as React from 'react'

import IconButton from '../common/IconButton/IconButton'
import HelpLink from '../HelpLink/HelpLink'

type Props = {
  readonly dslMode: boolean | null | undefined
  readonly onShowUIClick: () => unknown
  readonly onShowDslClick: () => unknown
  readonly helpUrl: string
}

class ShowDsl extends React.PureComponent<Props> {
  render(): React.ReactNode {
    const {dslMode, helpUrl, onShowUIClick, onShowDslClick} = this.props
    const title = dslMode === true ? 'Edit in UI' : 'View as code'
    return (
      <span>
        <Button onClick={dslMode === true ? onShowUIClick : onShowDslClick}>{title}</Button>
        <HelpLink url={helpUrl} Component={IconButton} icon="help" />
      </span>
    )
  }
}

export default ShowDsl
